<template>
  <div>
    <messenger></messenger>
  </div>
</template>

<script>
import {
  default as Messenger
} from "@/components/widgets/Messenger";
import store from '@/store';
import {
  default as router
} from '@/router';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Dashboard',
  data () {
    return {};
  },
  route: {},
  components: {
    Messenger
  },
  computed: mapGetters({
    device: 'device',
    help: 'help',
    StoreUser: 'user'
  }),
  created: function () {},
  destroyed () {},
  methods: {},
  mounted () {},
  watch: {}
};

</script>

<style type="text/css">


</style>

